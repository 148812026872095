import { HolisFile } from 'src/app/common/models/hol-attachments.model';
import { HolisLink } from '../../models/hol-attachments.model';
import { ConfirmationModalComponent } from 'src/app/common/modals/confirmation-modal/confirmation-modal.component';
import { Component, EventEmitter, Input, OnInit, Output, Inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { FileUploadAttachmentModalComponent } from '../file-upload-attachment-modal/file-upload-attachment-modal.component';
import { HolAttachments } from '../../models/hol-attachments.model';
import { HolFlight } from '../../models/hol-flight.model';
import { MatDialogRef } from '@angular/material/dialog';
import { getFileType, TypeFile } from '../../utils/files-utils';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-file-and-image-upload',
  templateUrl: './file-and-image-upload.component.html',
  styleUrls: ['./file-and-image-upload.component.scss'],
})
export class FileAndImageUploadComponent implements OnInit {
  @Input()
  public attachments: HolAttachments;
  @Input()
  public column: boolean;
  @Input()
  public disabled: boolean;
  @Input()
  public hideImage: boolean;
  @Input()
  public hideFile: boolean;
  @Input()
  public hideLink: boolean;
  @Input()
  public hideNote: boolean;
  @Input()
  public hideButtonTitle: boolean;
  @Input()
  public hideNotePreview: boolean;
  @Input()
  public multipleFileEnabled: boolean;

  @Input()
  public context: {
    module: string;
    category: string;
    htmlTitle: string;
    htmlScenario?: string;
    htmlDate;
    htmlNextInfo?;
    htmlTemplate: string;
    flight?: HolFlight;
    task?: any;
    initLabelName?: string;
  };

  @Input()
  public isPdf = false;
  @Output()
  public onAttachmentUpdated: EventEmitter<any> = new EventEmitter();
  public myFiles: HoleFilesData[] = [];
  protected readonly getFileType = getFileType;
  protected readonly TypeFile = TypeFile;

  constructor(public dialog: MatDialog, @Inject('$translate') private $translate) {}

  ngOnInit(): void {
    this.attachments = this.attachments || new HolAttachments();

    this.loadAndPrepareDisplay();
  }

  openAttachmentModal(type): void {
    let newObject;
    if (!this.attachments[type]) {
      newObject = type === 'link' ? new HolisLink() : new HolisFile();
    }

    const dialogRef: MatDialogRef<FileUploadAttachmentModalComponent> = this.dialog.open(FileUploadAttachmentModalComponent, {
      data: {
        context: this.context,
        attachment: this.attachments[type] || newObject,
        isOnDashboard: this.context.task ? this.context.task.isOnDashboard : false,
        type,
        displayImageTab: !this.attachments.image,
        displayLinkTab: !this.attachments.link,
      },
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result && result.attachment) {
        if (result.type === 'link') {
          this.onLinkAdded(result.attachment);
        } else {
          if (result.attachment.uploadedUrl) {
            if (result.type === 'image') {
              this.onUploadImageSuccess(result.attachment);
            } else if (result.type === 'file') {
              this.onUploadFileSuccess(result.attachment);
            } else if (result.type === 'files') {
              this.onUploadFilesSuccess(result.attachment);
            }
          }
        }
        this.loadAndPrepareDisplay();
      }
    });
  }

  onUploadFileSuccess($event) {
    this.attachments.file = {
      title: $event.title,
      fileName: $event.fileName,
      url: $event.uploadedUrl,
    };
    this.onAttachmentUpdated.emit();
  }

  onUploadFilesSuccess($event) {
    const file = {
      title: $event.title,
      fileName: $event.fileName,
      url: $event.uploadedUrl,
    };
    if (!this.attachments.files) {
      this.attachments.files = [];
    }

    this.attachments.files.push(file);

    this.onAttachmentUpdated.emit();
  }

  onNoteAdded($event: { note: any; file: any }) {
    this.attachments.note = $event.note;
    this.attachments.noteFile = {
      fileName: $event && $event.file ? $event.file.fileName : '',
      url: $event && $event.file ? $event.file.url : '',
    };
    this.onAttachmentUpdated.emit();
  }

  onUploadImageSuccess($event) {
    this.attachments.image = {
      title: $event.title,
      fileName: $event.fileName,
      url: $event.uploadedUrl,
    };

    this.onAttachmentUpdated.emit();
  }

  onLinkAdded($event) {
    this.attachments.link = $event;
    this.onAttachmentUpdated.emit();
  }

  removeFile(event) {
    event.stopPropagation();

    this.dialog
      .open(ConfirmationModalComponent, {
        data: {
          modalTitle: this.$translate.instant('COMMON.MODALS.CONFIRMATION.DELETE_FILE_TITLE'),
          modalContent: this.$translate.instant('COMMON.MODALS.CONFIRMATION.DELETE_FILE_CONTENT'),
          modalQuestion: this.$translate.instant('COMMON.MODALS.CONFIRMATION.QUESTION'),
        },
      })
      .afterClosed()
      .toPromise()
      .then(confirm => {
        if (confirm) {
          this.attachments.file = undefined;
          this.onAttachmentUpdated.emit();
          this.loadAndPrepareDisplay();
        }
      });
  }

  removeFiles(event, index) {
    event.stopPropagation();

    this.dialog
      .open(ConfirmationModalComponent, {
        data: {
          modalTitle: this.$translate.instant('COMMON.MODALS.CONFIRMATION.DELETE_FILE_TITLE'),
          modalContent: this.$translate.instant('COMMON.MODALS.CONFIRMATION.DELETE_FILE_CONTENT'),
          modalQuestion: this.$translate.instant('COMMON.MODALS.CONFIRMATION.QUESTION'),
        },
      })
      .afterClosed()
      .toPromise()
      .then(confirm => {
        if (confirm) {
          if (index > -1) {
            this.attachments.files.splice(index, 1);
          }
          this.onAttachmentUpdated.emit();
          this.loadAndPrepareDisplay();
        }
      });
  }

  removeImage(event) {
    event.stopPropagation();

    this.dialog
      .open(ConfirmationModalComponent, {
        data: {
          modalTitle: this.$translate.instant('COMMON.MODALS.CONFIRMATION.DELETE_IMAGE_TITLE'),
          modalContent: this.$translate.instant('COMMON.MODALS.CONFIRMATION.DELETE_IMAGE_CONTENT'),
          modalQuestion: this.$translate.instant('COMMON.MODALS.CONFIRMATION.QUESTION'),
        },
      })
      .afterClosed()
      .toPromise()
      .then(confirm => {
        if (confirm) {
          this.attachments.image = undefined;
          this.onAttachmentUpdated.emit();
          this.loadAndPrepareDisplay();
        }
      });
  }

  removeNote(event) {
    event.stopPropagation();

    this.dialog
      .open(ConfirmationModalComponent, {
        data: {
          modalTitle: this.$translate.instant('COMMON.MODALS.CONFIRMATION.NOTE_TITLE'),
          modalContent: this.$translate.instant('COMMON.MODALS.CONFIRMATION.NOTE_CONTENT'),
          modalQuestion: this.$translate.instant('COMMON.MODALS.CONFIRMATION.QUESTION'),
        },
      })
      .afterClosed()
      .toPromise()
      .then(confirm => {
        if (confirm) {
          this.attachments.note = undefined;
          this.attachments.noteFile = undefined;
          this.onAttachmentUpdated.emit();
          this.loadAndPrepareDisplay();
        }
      });
  }

  removeLink(event) {
    event.stopPropagation();

    this.dialog
      .open(ConfirmationModalComponent, {
        data: {
          modalTitle: this.$translate.instant('COMMON.MODALS.CONFIRMATION.DELETE_LINK_TITLE'),
          modalContent: this.$translate.instant('COMMON.MODALS.CONFIRMATION.DELETE_LINK_CONTENT'),
          modalQuestion: this.$translate.instant('COMMON.MODALS.CONFIRMATION.QUESTION'),
        },
      })
      .afterClosed()
      .toPromise()
      .then(confirm => {
        if (confirm) {
          this.attachments.link = undefined;
          this.onAttachmentUpdated.emit();
          this.loadAndPrepareDisplay();
        }
      });
  }

  public removeHolFilesData(event: MouseEvent, holFileData: HoleFilesData) {
    if (holFileData.source === 'file') {
      this.removeFile(event);
    } else if (holFileData.source === 'files') {
      this.removeFiles(event, holFileData.index);
    } else if (holFileData.source === 'image') {
      this.removeImage(event);
    } else if (holFileData.source === 'link') {
      this.removeLink(event);
    }
  }

  openFile(url: string) {
    window.open(url, '_blank');
  }

  private loadAndPrepareDisplay() {
    this.myFiles = [];
    if (this.attachments.file) {
      const type = getFileType(this.attachments.file.url);
      this.myFiles.push({
        fileName: this.attachments.file.fileName,
        url: this.attachments.file.url,
        type: type,
        title: this.attachments.file.title,
        canBeDeleted: this.attachments.file.canBeDeleted,
        urlImage: 'assets/images/attachment/other.png',
        source: 'file',
      });
    }
    if (this.attachments.image) {
      const type = getFileType(this.attachments.image.url);

      this.myFiles.push({
        fileName: this.attachments.image.fileName,
        url: this.attachments.image.url,
        type: TypeFile.image,
        title: this.attachments.image.title,
        canBeDeleted: this.attachments.image.canBeDeleted,
        urlImage: this.attachments.image.url,
        source: 'image',
      });
    }
    if (this.attachments.files) {
      this.attachments.files.forEach((file, index) => {
        const type = getFileType(file.url);

        let urlImage = 'assets/images/attachment/other.png';
        switch (type) {
          case TypeFile.excel:
            urlImage = 'assets/images/attachment/excel.svg';
            break;
          case TypeFile.pdf:
            urlImage = 'assets/images/attachment/pdf.svg';
            break;
          case TypeFile.url:
            urlImage = 'assets/images/attachment/url.svg';
            break;
          case TypeFile.word:
            urlImage = 'assets/images/attachment/word.svg';
            break;
          case TypeFile.archive:
            urlImage = 'assets/images/attachment/archive.svg';
            break;
          case TypeFile.other:
            urlImage = 'assets/images/attachment/other.svg';
            break;
          case TypeFile.image:
            urlImage = file.url;
            break;
        }
        this.myFiles.push({
          fileName: file.fileName,
          url: file.url,
          type: type,
          title: file.title,
          canBeDeleted: file.canBeDeleted,
          urlImage: urlImage,
          index: index,
          source: 'files',
        });
      });
    }
    if (this.attachments.link) {
      const type = getFileType(this.attachments.link.url);
      this.myFiles.push({
        fileName: this.attachments.link.url,
        url: this.attachments.link.url,
        type: TypeFile.url,
        title: this.attachments.link.title,
        canBeDeleted: true,
        urlImage: 'assets/images/attachment/url.png',
        source: 'link',
      });
    }
  }
}

interface HoleFilesData {
  title?: string;
  fileName: string;
  url: SafeResourceUrl | string;
  canBeDeleted?: boolean;
  type?: TypeFile;
  urlImage?: string;
  index?: number;
  source: string;
}
