<table>
  <thead>
    <tr>
      <th></th>
      <th>{{ moduleConfig.config.translateKey + '.COMMON.BY_SMS' | translate }}</th>
      <th>{{ moduleConfig.config.translateKey + '.COMMON.BY_MAIL' | translate }}</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngIf="notifyFunction">
      <th>
        {{ 'DASHBOARD.MODAL.NOTIFY_FUNCTION' | translate }}
      </th>
      <td>
        <mat-checkbox
          (ngModelChange)="callSaveNotifyFunction()"
          [(ngModel)]="notifyFunction.sendBySms"
          [color]="color"
          [disabled]="notifyFunction.disabled"
        >
        </mat-checkbox>

        <mat-slide-toggle
          (toggleChange)="callSaveNotifyFunction()"
          [checked]="notifyFunction.sendBySms"
          [color]="color"
          [disabled]="notifyFunction.disabled"
        ></mat-slide-toggle>
      </td>
      <td>
        <mat-checkbox
          (ngModelChange)="callSaveNotifyFunction()"
          [(ngModel)]="notifyFunction.sendByMail"
          [color]="color"
          [disabled]="notifyFunction.disabled"
        >
        </mat-checkbox>
      </td>
    </tr>
    <tr *ngFor="let notif of notifications">
      <th>{{ notif.name }}</th>
      <td>
        <!--
            <mat-checkbox
              (change)="callSaveNotifications()"
              (ngModelChange)="callSaveNotifications()"
              [(ngModel)]="notif.sendBySms"
              [color]="color"
              [disabled]="notif.disabled || notif.readOnly || notif.disabled === undefined"
              aria-label="Send Notifications by SMS"
              id="notificationSMS{{ notif.objectId + type }}"
            >
            </mat-checkbox>
              (change)="callSaveNotifications()"
      -->
        <mat-slide-toggle
          [(ngModel)]="notif.sendBySms"
          [checked]="notif.sendBySms"
          [color]="color"
          [disabled]="notif.disabled || notif.readOnly || notif.disabled === undefined"
          aria-label="Send Notifications by SMS"
          id="notificationSMS{{ notif.objectId + type }}"
        >
        </mat-slide-toggle>
      </td>
      <td>
        <!--(change)="callSaveNotifications()" -->
        <mat-slide-toggle
          [(ngModel)]="notif.sendByMail"
          [checked]="notif.sendByMail"
          [color]="color"
          [disabled]="notif.disabled || notif.readOnly || notif.disabled === undefined"
          aria-label="Send Notifications by MAIL"
          id="notificationMail{{ notif.objectId + type }}"
        >
        </mat-slide-toggle>
        <!--
      <mat-checkbox
        (change)="callSaveNotifications()"
        (ngModelChange)="callSaveNotifications()"
        [(ngModel)]="notif.sendByMail"
        [color]="color"
        [disabled]="notif.disabled || notif.readOnly || notif.disabled === undefined"
        aria-label="Send Notifications by MAIL"
        id="notificationMail{{ notif.objectId + type }}"
      >
      </mat-checkbox>
      --></td>
    </tr>
  </tbody>
</table>
