<!--PREVIEW used for pdf-->
<div class="content-row">
  <ng-container *ngIf="myFiles && myFiles.length > 0; else empty">
    <div class="attachment-toolbar">
      <div>
        <button (click)="openAttachmentModal('files')" [disabled]="disabled" class="square upload" matTooltip="Téléverser un fichier">
          <span class="material-symbols-outlined">upload</span>
        </button>
      </div>
    </div>
    <div *ngFor="let myFile of myFiles" class="row-file">
      <div class="picture-preview">
        <picture (click)="openFile(myFile.url)">
          <img
            [ngClass]="{ files: myFile.type != TypeFile.image, image: myFile.type == TypeFile.image }"
            [src]="myFile.urlImage"
            alt="{{ myFile.title }}"
          />
        </picture>
      </div>
      <div class="row-description">
        <div class="file-description">
          <span>{{ myFile.title }}</span>
        </div>
        <div class="file-name">
          <span>{{ myFile.fileName }}</span>
        </div>
        <div class="file-action">
          <button
            (click)="removeHolFilesData($event, myFile)"
            [disabled]="disabled"
            class="square upload"
            matTooltip="Supprimer la piece jointe"
          >
            <span class="material-symbols-outlined">delete</span>
          </button>
          <!--
          <span><a (click)="removeHolFilesData($event, myFile)" mat-button routerLink=".">Supprimer</a></span> -
          <span>Modifer</span>
          -->
        </div>
      </div>
    </div>
  </ng-container>

  <ng-template #empty>
    <div (click)="!disabled && openAttachmentModal('files')" class="no-attachment">
      <div class="no-attachment-content">
        <span class="material-symbols-outlined">upload</span>
        <span>Aucune pièce jointe</span>
      </div>
    </div>
  </ng-template>
</div>
