<div class="hol-dialog-wrapper confirmation-modal">
  <mat-toolbar class="mat-toolbar">
    <div class="title" mat-dialog-title>
      <span [innerHTML]="modalTitle"></span>
    </div>
    <button mat-dialog-close mat-icon-button tabindex="-1">
      <mat-icon>close</mat-icon>
    </button>
  </mat-toolbar>
  <mat-dialog-content>
    <div class="content-grid">
      <p [innerHtml]="modalContent"></p>
      <p [innerHtml]="modalQuestion"></p>
    </div>
  </mat-dialog-content>

  <mat-dialog-actions>
    <div class="action-bar">
      <button (click)="cancel()" class="action action-refuse">
        <span class="material-symbols-outlined">cancel</span>
        <span>{{ 'COMMON.NO' | translate | uppercase }}</span>
      </button>
      <div class="fill-space"></div>

      <button (click)="valid()" class="action action-validate">
        <span class="material-symbols-outlined">check</span>
        <span>{{ 'COMMON.YES' | translate | uppercase }}</span>
      </button>
    </div>
  </mat-dialog-actions>
</div>
