import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

import { MatButtonToggleGroup } from '@angular/material/button-toggle';

import { difference, remove, uniq } from 'lodash';

import { FltApplicability } from '../../../flt/models/flt-applicability';
import { HolObject } from '../../models/hol-object';
import { HelperService } from '../../services/helper.service';
import { RolesService } from '../../services/roles.service';
import { AclSelectorRole } from './acl-selector-role.model';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-acl-selector',
  templateUrl: './acl-selector.component.html',
  styleUrls: ['./acl-selector.component.scss'],
})
export class AclSelectorComponent implements OnInit, OnChanges {
  roles: AclSelectorRole[] = [];
  userRoles: AclSelectorRole[] = [];
  @Input() startAllSelected = true;
  @Input() object: HolObject;
  @Input() formGroup: FormGroup;
  @Input() readOnly: boolean;
  @Input() isSelectedModalCrisis: boolean;
  @Input() applicability?: FltApplicability;
  @Input() alwaysOneSelected?: boolean = true;
  @Input() aclObject?: HolObject;
  @Input() extendACLOnly = false;
  @Input() selectValuesFromModuleFilter = true;
  @Input() unselectAllACL = false;
  @Input() isCompact = false;
  @Output() public updateACLFromHand = new EventEmitter();
  @Output() public finishUpdatingAcls? = new EventEmitter<any>();
  @Output() public viaCrisisModal? = new EventEmitter(); //Emit true if userRoles.length>1 Else; false

  @ViewChild('group', { static: false }) btnGroup: MatButtonToggleGroup;

  enabledRoles: AclSelectorRole[] = [];

  private aclControl;
  private currentModuleAcl;
  private matchApplicabilityRoles;
  private componentIsInit = false;

  constructor(private rolesService: RolesService, private helperService: HelperService) {}

  private _moduleName = new BehaviorSubject<string>('');

  @Input() get moduleName(): string {
    return this._moduleName.value;
  }

  set moduleName(value) {
    this._moduleName.next(value);
  }

  private _limitTo: Parse.ACL;

  @Input()
  get limitTo(): Parse.ACL {
    return this._limitTo;
  }

  set limitTo(value) {
    this.limitACL(value);
    this._limitTo = value;
  }

  get acl(): Parse.ACL {
    if (this.object instanceof HolObject) {
      let acl = this.object.acl;
      if (this.aclObject) {
        acl = this.aclObject.acl;
      }
      return acl;
    }
  }

  async ngOnInit() {
    if (this.selectValuesFromModuleFilter) {
      this.rolesService.$companiesRolesFilter.subscribe(el => {
        this.currentModuleAcl = el;
        if (this.componentIsInit) {
          this.checkDefaultRole();
          this.updateACL();
        }
      });
    }

    if (!this.object) {
      throw new Error('Object is required for AclSelector');
    }

    if (this.formGroup) {
      const existingControl = this.formGroup.get('acl');
      if (existingControl) {
        this.aclControl = existingControl;
      } else {
        this.aclControl = new FormControl(this.acl, Validators.required);
        this.formGroup.addControl('acl', this.aclControl);
      }
    }

    this._moduleName.subscribe(async moduleName => {
      if (this.isSelectedModalCrisis) {
        this.roles = (await this.rolesService.getUserCompanyRolesByUniverse(moduleName.toUpperCase())).map(r => new AclSelectorRole(r));
        this.userRoles = this.roles.filter(role => this.object.companies.includes(role.company));
      } else {
        this.roles = (await this.rolesService.getUserCompanyRolesByUniverse(moduleName.toUpperCase())).map(r => new AclSelectorRole(r));
        this.userRoles = this.roles.filter(role => role.userWriteRoles.length);
      }
      this.viaCrisisModal.emit(this.userRoles.length > 1);
      this.limitACL(this.limitTo);
    });

    this.componentIsInit = true;

    setTimeout(() => {
      if (this.acl && Object.keys(this.acl.permissionsById).length && !this.acl.getPublicWriteAccess()) {
        this.checkFromACL();
      } else {
        this.checkDefaultRole();
      }
      const rolesFiltrer = this.userRoles.filter(role => {
        return !role.disabledFromLimit && this.selectValuesFromModuleFilter;
      });
      if (rolesFiltrer.length === 1) {
        const roleToSelect = rolesFiltrer[0];
        this.btnGroup.value = [roleToSelect];
      } else if (this.startAllSelected) {
        if (this.object.acl) {
          this.btnGroup.value = rolesFiltrer.filter(r => this.matchAcl(r, this.object.acl));
        } else {
          this.btnGroup.value = rolesFiltrer;
        }
      }
      this.updateACL();
    });
    this.componentIsInit = true;
  }

  ngOnChanges(changes: SimpleChanges) {
    const changedApplicability = changes.applicability;
    if (changedApplicability && !changedApplicability.firstChange) {
      const newApplicability: FltApplicability = changedApplicability.currentValue;
      if (
        (this.matchApplicabilityRoles && !newApplicability.flights) ||
        (newApplicability.flights && newApplicability.flights.length >= 1)
      ) {
        const newSelectedRoles = this.btnGroup.value;
        const flightsACL: Parse.ACL[] = newApplicability.flights ? uniq(newApplicability.flights.map(f => f.acl)) : null;
        const newMatchApplicabilityRoles = [];
        if (flightsACL) {
          flightsACL.forEach(acl => {
            if (acl) {
              this.roles.filter(r => {
                if (this.matchAcl(r, acl)) {
                  newMatchApplicabilityRoles.push(r);
                }
              });
            }
          });
        }

        const applicabilityRolesToRemove = difference(this.matchApplicabilityRoles, newMatchApplicabilityRoles);
        if (applicabilityRolesToRemove.length) {
          remove(newSelectedRoles, r => {
            return applicabilityRolesToRemove.includes(r);
          });
        }

        const applicabilityRolesToAdd = difference(newMatchApplicabilityRoles, this.matchApplicabilityRoles);
        if (applicabilityRolesToAdd.length) {
          applicabilityRolesToAdd.forEach(r => {
            newSelectedRoles.push(r);
          });
        }

        this.matchApplicabilityRoles = newMatchApplicabilityRoles;
        this.btnGroup.value = newSelectedRoles;
        this.updateACL();
      }
    }
    if (this.unselectAllACL && this.unselectAllACL == true) {
      this.deselectAll();
    }
  }

  updateACL(fromButton = false) {
    if (this.readOnly) {
      return;
    }
    const selectedRoles = this.btnGroup.value;
    let acl = new Parse.ACL();
    if (this.aclObject) {
      acl = this.acl;
    }

    if (!selectedRoles || !selectedRoles.length) {
      this.aclControl.setValue(null);
    } else {
      selectedRoles.forEach(r => {
        if (r.initialWriteRoles) {
          r.initialWriteRoles.forEach(wr => {
            acl.setRoleWriteAccess(wr.parseRole, true);
          });
        }
        r.userWriteRoles.forEach(wr => {
          acl.setRoleWriteAccess(wr.parseRole, true);
        });
        r.writeRoles.forEach(wr => {
          acl.setRoleReadAccess(wr.parseRole, true);
        });
        r.readRoles.forEach(rr => {
          acl.setRoleReadAccess(rr.parseRole, true);
        });
      });
      if (this.aclControl) {
        this.aclControl.setValue(acl);
      }
    }

    //   this.object.acl = this.helperService.mergeAcls(this.object.acl, acl);
    this.object.acl = acl;

    //todo merge
    if (fromButton) {
      this.updateACLFromHand.emit(true);
    }
    if (this.finishUpdatingAcls) this.finishUpdatingAcls.emit(selectedRoles);
  }

  mixColors(color1, color2, weight) {
    return HelperService.mixColors(color1, color2, weight);
  }

  isSelected(role: AclSelectorRole) {
    return this.btnGroup.value && this.btnGroup.value.indexOf(role) > -1;
  }

  toggleSelectAll() {
    if (this.enabledRoles.length === this.btnGroup.value.length) {
      this.btnGroup.value = [];
    } else {
      this.btnGroup.value = this.enabledRoles;
    }
    this.updateACL();
  }

  deselectAll() {
    this.btnGroup.value = [];
    this.unselectAllACL = false;
    this.updateACL();
  }

  selectAll() {
    this.btnGroup.value = this.enabledRoles;
    this.updateACL();
  }

  private checkFromACL() {
    this.roles.forEach(r => {
      r.initialWriteRoles = r.writeRoles.filter(uwr => this.acl.getRoleWriteAccess(uwr.parseRole));
    });
    this.btnGroup.value = this.roles.filter(r => {
      const retVal = !!r.writeRoles.find(uwr => {
        return this.acl.getRoleWriteAccess(uwr.parseRole);
      });

      return retVal;
    });
  }

  private checkDefaultRole() {
    const rolesEnabled = this.roles.filter(r => r.userWriteRoles.length);
    if (rolesEnabled && rolesEnabled.length === 1) {
      this.btnGroup.value = rolesEnabled;
    } /*else {
      if (this.selectValuesFromModuleFilter) {
        rolesEnabled = rolesEnabled.filter(r => this.currentModuleAcl.findIndex(c => c === r.company) !== -1);
        this.btnGroup.value = rolesEnabled;
      }
    }*/
  }

  private limitACL(value: Parse.ACL) {
    this.roles.forEach(r => {
      r.disabledFromLimit = !this.matchAcl(r, value);
    });
    this.enabledRoles = this.roles.filter(r => r.userWriteRoles.length && !r.disabledFromLimit);
    if (this.btnGroup && this.btnGroup.value && this.btnGroup.value.length) {
      this.btnGroup.value = this.btnGroup.value.filter(r => !r.disabledFromLimit);

      this.updateACL();
    }
  }

  private matchAcl(r: AclSelectorRole, value: Parse.ACL): boolean {
    if (!value || value.getPublicWriteAccess()) {
      return true;
    }
    for (const writeRole of r.writeRoles) {
      if (value.getRoleWriteAccess(writeRole.parseRole)) {
        return true;
      }
    }
    return false;
  }
}
