import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { HelperService } from '../../services/helper.service';
import { cloneDeep } from 'lodash';
import * as moment from 'moment';
import { HolNextInfo } from '../../models/hol-next-info.model';
import { nextInfoType } from '../../components/hol-next-info/hol-next-info.component';

@Component({
  selector: 'app-detail-next-info-modal',
  templateUrl: './detail-next-info-modal.component.html',
  styleUrls: ['./detail-next-info-modal.component.scss'],
})
export class DetailNextInfoModalComponent implements OnInit {
  title: string;
  public context: {
    module: string;
    category: string;
    htmlTitle: string;
    htmlScenario?: string;
    htmlDate;
    htmlNextInfo?;
    htmlTemplate: string;
  };
  info: HolNextInfo;
  isReadOnly: boolean;
  isUtc: boolean;
  canEditNi: boolean;
  nextInfoTime;

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: any,
    public helperService: HelperService,
    public dialogRef: MatDialogRef<DetailNextInfoModalComponent>,
  ) {
    this.title = data.title;
    this.context = data.context;
    this.context.htmlNextInfo = cloneDeep(data.info);
    this.info = cloneDeep(data.info);
    this.isReadOnly = data.isReadOnly;
    this.isUtc = data.isUtc;
    this.canEditNi = data.canEditNi;
  }

  ngOnInit(): void {
    if (this.canEditNi) {
      if (this.isUtc) {
        this.nextInfoTime = moment.utc(this.info.nextInfoTime);
      } else {
        this.nextInfoTime = moment(this.info.nextInfoTime);
      }
    }
  }

  save(): void {
    if (this.canEditNi) {
      this.info.nextInfoTime = this.nextInfoTime;
    }
    this.dialogRef.close(this.info);
  }

  saveNextInfo(nextInfo: nextInfoType) {
    this.nextInfoTime = nextInfo.nextInfoTime;
  }
}
